
import React, { useState } from "react"; // Import React and useState
import axios from 'axios';
import { withRouter } from 'react-router-dom'; // Import withRouter
// Chakra imports
import {
  Box,
  Button,
  DarkMode,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  Link,
  Switch,
  Text,
} from "@chakra-ui/react";

// Assets
import basic from "assets/img/basic-auth.png";

// Custom components
import Footer from "components/Footer/Footer";
import GradientBorder from "components/GradientBorder/GradientBorder";

// Icons
import { FaApple, FaFacebook, FaGoogle } from "react-icons/fa";

function SignUpBasic({ history }) {
  const titleColor = "white";
  const textColor = "gray.400";

  //Modified by Prasanth Starts
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password_hash: '',
  });

  const handleSignUp = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post('https://enter22.azurewebsites.net/api/users/register', formData);
      console.log('User registered:', response.data);
      history.push('/dashboard/default');
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  //Modified by Prasanth ends
  return (
    <Flex
      direction='column'
      alignSelf='center'
      justifySelf='center'
      overflow='hidden'>
      <Box
        position='absolute'
        minH={{ base: "70vh", md: "50vh" }}
        w={{ md: "calc(100vw - 50px)" }}
        borderRadius={{ md: "15px" }}
        left='0'
        right='0'
        bgRepeat='no-repeat'
        overflow='hidden'
        zIndex='-1'
        top='0'
        bgImage={basic}
        bgSize='cover'
        mx={{ md: "auto" }}
        mt={{ md: "14px" }}></Box>
      <Flex
        alignItems='center'
        justifyContent='start'
        style={{ userSelect: "none" }}
        flexDirection='column'
        mx={{ base: "auto" }}
        w={{ base: "100%", md: "50%", lg: "42%" }}>
        <Flex
          direction='column'
          textAlign='center'
          justifyContent='center'
          align='center'
          mt={{ base: "120px", md: "120px", lg: "150px" }}
          mb='50px'
          mx='10px'>
          <Text
            fontSize='4xl'
            lineHeight='39px'
            color='white'
            fontWeight='bold'>
            Welcome!
          </Text>
          <Text
            fontSize='md'
            color='white'
            fontWeight='500'
            mt='10px'
            w={{ base: "100%", md: "90%", lg: "90%", xl: "70%" }}>
            Your entrepreneurship journey starts here..  !!
          </Text>
        </Flex>
        <GradientBorder
          p='2px'
          me={{ base: "none", lg: "30px", xl: "none" }}
          mb={{ base: "50px", lg: "150px" }}>
          <Flex
            background='transparent'
            borderRadius='30px'
            direction='column'
            p='40px'
            minW={{ base: "unset", md: "430px", xl: "450px" }}
            w='100%'
            mx={{ base: "0px" }}
            bg={{
              base: "rgb(19,21,56)",
            }}>
            <Text
              fontSize='xl'
              color='white'
              fontWeight='bold'
              textAlign='center'
              mb='22px'>
              Register With
            </Text>
            <HStack spacing='15px' justify='center' mb='22px'>
              <GradientBorder borderRadius='15px'>
                <Flex
                  _hover={{ filter: "brightness(120%)" }}
                  transition='all .25s ease'
                  cursor='pointer'
                  justify='center'
                  align='center'
                  bg='rgb(19,21,54)'
                  w='71px'
                  h='71px'
                  borderRadius='15px'>
                  <Link href='#'>
                    <Icon
                      color={titleColor}
                      as={FaFacebook}
                      w='30px'
                      h='30px'
                      _hover={{ filter: "brightness(120%)" }}
                    />
                  </Link>
                </Flex>
              </GradientBorder>
              <GradientBorder borderRadius='15px'>
                <Flex
                  _hover={{ filter: "brightness(120%)" }}
                  transition='all .25s ease'
                  cursor='pointer'
                  justify='center'
                  align='center'
                  bg='rgb(19,21,54)'
                  w='71px'
                  h='71px'
                  borderRadius='15px'>
                  <Link href='#'>
                    <Icon
                      color={titleColor}
                      as={FaApple}
                      w='30px'
                      h='30px'
                      _hover={{ filter: "brightness(120%)" }}
                    />
                  </Link>
                </Flex>
              </GradientBorder>
              <GradientBorder borderRadius='15px'>
                <Flex
                  _hover={{ filter: "brightness(120%)" }}
                  transition='all .25s ease'
                  cursor='pointer'
                  justify='center'
                  align='center'
                  bg='rgb(19,21,54)'
                  w='71px'
                  h='71px'
                  borderRadius='15px'>
                  <Link href='#'>
                    <Icon
                      color={titleColor}
                      as={FaGoogle}
                      w='30px'
                      h='30px'
                      _hover={{ filter: "brightness(120%)" }}
                    />
                  </Link>
                </Flex>
              </GradientBorder>
            </HStack>
            <Text
              fontSize='lg'
              color='gray.400'
              fontWeight='bold'
              textAlign='center'
              mb='22px'>
              or
            </Text>
            <FormControl>
              <FormLabel
                color={titleColor}
                ms='4px'
                fontSize='sm'
                fontWeight='500'>
                Name
              </FormLabel>

              <GradientBorder
                mb='24px'
                h='50px'
                w={{ base: "100%", lg: "fit-content" }}
                borderRadius='20px'>
                <Input
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  defaultValue={formData.name}
                  color={titleColor}
                  bg={{
                    base: "rgb(19,21,54)",
                  }}
                  border='transparent'
                  borderRadius='20px'
                  fontSize='sm'
                  size='lg'
                  w={{ base: "100%", md: "346px" }}
                  maxW='100%'
                  h='46px'
                  type='text'
                  placeholder='Your name'
                />
              </GradientBorder>
              <FormLabel
                color={titleColor}
                ms='4px'
                fontSize='sm'
                fontWeight='500'>
                Email
              </FormLabel>
              <GradientBorder
                mb='24px'
                h='50px'
                w={{ base: "100%", lg: "fit-content" }}
                borderRadius='20px'>
                <Input
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  defaultValue={formData.email}
                  color={titleColor}
                  bg={{
                    base: "rgb(19,21,54)",
                  }}
                  border='transparent'
                  borderRadius='20px'
                  fontSize='sm'
                  size='lg'
                  w={{ base: "100%", md: "346px" }}
                  maxW='100%'
                  h='46px'
                  type='email'
                  placeholder='Your email address'
                />
              </GradientBorder>
              <FormLabel
                color={titleColor}
                ms='4px'
                fontSize='sm'
                fontWeight='500'>
                Password
              </FormLabel>
              <GradientBorder
                mb='24px'
                h='50px'
                w={{ base: "100%", lg: "fit-content" }}
                borderRadius='20px'>
                <Input
                  name="password_hash"
                  value={formData.password_hash}
                  onChange={handleInputChange}
                  defaultValue={formData.password_hash}
                  color={titleColor}
                  bg={{
                    base: "rgb(19,21,54)",
                  }}
                  border='transparent'
                  borderRadius='20px'
                  fontSize='sm'
                  size='lg'
                  w={{ base: "100%", md: "346px" }}
                  maxW='100%'
                  h='46px'
                  type='password'
                  placeholder='Your password'
                />
              </GradientBorder>
              <FormControl display='flex' alignItems='center' mb='24px'>
                <DarkMode>
                  <Switch id='remember-login' colorScheme='brand' me='10px' />
                </DarkMode>

                <FormLabel
                  color={titleColor}
                  htmlFor='remember-login'
                  mb='0'
                  fontWeight='500'>
                  Remember me
                </FormLabel>
              </FormControl>
              <Button
                variant='brand'
                fontSize='10px'
                onClick={handleSignUp}
                w='100%'
                h='45'
                mb='20px'>
                SIGN UP
              </Button>
            </FormControl>
            <Flex
              flexDirection='column'
              justifyContent='center'
              alignItems='center'
              maxW='100%'
              mt='0px'>
              <Text color={textColor} fontWeight='medium'>
                Already have an account?
                <Link
                  color={titleColor}
                  as='span'
                  ms='5px'
                  href='#'
                  fontWeight='bold'>
                  Sign In
                </Link>
              </Text>
            </Flex>
          </Flex>
        </GradientBorder>
      </Flex>
      <Box
        maxW='100%'
        w={{ base: "335px", md: "1044px" }}
        mx={{ base: "auto" }}>
        <Footer />
      </Box>
    </Flex>
  );
}

//export default SignUpBasic;
export default withRouter(SignUpBasic);
